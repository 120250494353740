import React from "react"
import Layout from "../components/layout"

const PrivacyPage = () => {
	return (
	<Layout privatePage={true} activePath='privacy' pageTitle="Privacy Policy" pageDescription="Privacy Policy">
		<div className="text-centered" style={{"position":"relative"}}>
			<img style={{display:"inline-block", width:"90%"}} src="https:///consent.boysencdn.com/img/policy.png" />
			<a style={{display:"inline-block", width:"12%", position:"absolute", bottom:"1vh", left:"44%"}} href="https://npcregistration.privacy.gov.ph/certificate/organizationRegistration/65e53f85335d7e2e657cfbb6" target={"_blank"}  rel={"noreferrer"} >
				<img style={{display:"block", width:"100%"}} src="https://consent.boysencdn.com/img/full/npc202504.png" alt="NPC Registration" srcset="https:///consent.boysencdn.com/img/full/npc202504.png 743w, https:///consent.boysencdn.com/img/high/npc202504.png 2048w, https:///consent.boysencdn.com/img/med/npc202504.png 1200w, https:///consent.boysencdn.com/img/low/npc202504.png 500w, https:///consent.boysencdn.com/img/tiny/npc202504.png 128w" />
			</a>
		</div>
	</Layout>
)}

export default PrivacyPage
